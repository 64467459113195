import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./component/auth/login";
import Product from "./component/auth/product";
import Dashboard from "./component/auth/dashboard";
import Projectdetails from "./component/auth/projectdetails";
import Coloradd from "./component/auth/coloradd";
import Categery from "./component/auth/categery";
import FormulaTable from "./component/auth/formulatable";
import Test from "./component/auth/test";
import Report from "./component/auth/reportpage";

const App = () => {

  
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/product" element={<Product />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/projectdetails" element={<Projectdetails />} />
          <Route path="/coloradd" element={<Coloradd />} />
          <Route path="/Categery" element={< Categery />} />
          <Route path="/formulatable" element={< FormulaTable />} />
           <Route path="/Reportpage"   element={<Report/>} />
          <Route path="/test" element={< Test />} />


          

          
        </Routes>
      </Router>
      <Router basename="/dashboard">
</Router>

    </div>
  );
};

export default App;
